import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import GlobalStyle from './GlobalStyle';
import GlobalFonts from './GlobalFonts';

class Theme extends Component {
    onlyWithFile = edge => edge.fontFile.file;
    toCss = edge => {
        return `@font-face {
                 font-family: '${ edge.name }' ;
                 font-style: ${ edge.style.toLowerCase() };
                 font-weight: ${ edge.weight[1].content };
                 font-display: swap;
                 src: local('${ edge.name }'), 
                      url(/fonts/${ edge.name }-${ edge.weight[0].content }.${ edge.fontFile.file.url.substring(edge.fontFile.file.url.lastIndexOf('.') + 1) }) 
                      format('truetype');
            }`;
    };

    generateFontFaces = allFonts =>
        allFonts
            .filter(this.onlyWithFile)
            .map(this.toCss)
            .join(' ')

    render () {
        const { fonts, theme } = this.props;
        const fontFaces = this.generateFontFaces(fonts);
        return (
            <div>
                <GlobalFonts fontFaces={ fontFaces } />
                <GlobalStyle themes={theme.themes} base={theme.base} />
            </div>
        );
    }
}

const ThemeProvider = () => {
    return (
        <StaticQuery
            query={graphql`
            query themeData {
              styleGuide: allContentfulWebsite(filter: {slug: {eq: "website"}, styleGuide: {themes: {themes: {themes: {elemMatch: {}}}}}}) {
                edges {
                  node {
                    id
                    styleGuide {
                      typography {
                        name
                        typeface
                        style
                        weight {
                          content
                        }
                        fontFile {
                          id
                          file {
                            url
                            fileName
                            contentType
                          }
                        }
                      }
                      themes {
                        themes {
                          themes {
                            family
                            weight
                            typeface
                            name
                            fontsize {
                              A
                              D
                              M
                              T
                            }
                            lineheight {
                              A
                              D
                              M
                              T
                            }
                          }
                          base {
                            fontsize
                            lineheight
                            ratio {
                              A
                              D
                              M
                              T
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
        }
      `}
            render={data => (
                <Theme fonts={data.styleGuide.edges[0].node.styleGuide.typography}
                    theme={data.styleGuide.edges[0].node.styleGuide.themes.themes } />
            )}
        />
    );
};

export default ThemeProvider;
