import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export const useEvent = () => {
    const { allContentfulPartner } = useStaticQuery(
        graphql`
            query AllCustomerPartner {
                allContentfulPartner {
                    nodes {
                        name
                        contentful_id
                        link
                        logo {
                            file {
                                url
                            }
                            id
                        }
                    }
                }
            }
        `
    );
    return allContentfulPartner;
};

const StaticProviderPartner = ({ contentIds, children }) => {
    const { nodes } = useEvent();
    const data = contentIds.map(id => nodes.find(node => node.contentful_id === id));

    const component = React.cloneElement(children, { data: data });
    return component;
};

export default StaticProviderPartner;
