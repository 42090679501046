/* eslint-disable spaced-comment,no-trailing-spaces,object-curly-spacing,padded-blocks,no-multiple-empty-lines */
import React, {useEffect} from 'react';
import {graphql} from 'gatsby';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {extractActiveValue} from '../utils/Extract';
import {initialize as initTagManager} from '../utils/TagManager';
import {initialize as initStructuredData} from '../utils/StructuredData';
import {getComponent, getSection} from '../utils/WebComponents';

import Container from '../components/Container';
import Structure from './Structure';
import HelmetHeader from '../components/HelmetHeader';
import StaticProviderSchedule from '../staticProviders/StaticProviderSchedule';
import StaticProviderSpeakers from '../staticProviders/StaticProviderSpeakers';
import StaticProviderPublication from '../staticProviders/StaticProviderPublication';

import * as WebComponents from 'web-components-common';
import StaticProviderEvent from '../staticProviders/StaticProviderEvent';
import StaticProviderCustomerCase from '../staticProviders/StaticProviderCustomerCase';
import StaticProviderPartner from '../staticProviders/StaticProviderPartner';


if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]');
}

const Generated = styled.div`
  display : flex;
  flex-direction: column;
`;

const PageTemplate = ({pageContext, data, location}) => {

    const {website} = data;
    const {structure} = data.page;

    useEffect(() => {
        const seo = JSON.parse(data.website.edges[0].node.seo.seoConfiguration.value);
        if (seo.global.GoogleTagManager) {
            initTagManager(seo.global.GoogleTagManager.head, seo.global.GoogleTagManager.body);
        }

        const seoPage = getSeoPage(seo.pages, pageContext.slug);
        if (seoPage && seoPage.Data && seoPage.Data.structuredData) {
            initStructuredData(seoPage.Data.structuredData[pageContext.locale]);
        }
    });

    const dom = structure && structure.dom ? extractActiveValue(JSON.parse(structure.dom.dom)) : null;
    let websiteNode = website.edges[0].node;
    const domNavigation = websiteNode.menuHeaderStructure && websiteNode.menuHeaderStructure.dom ? extractActiveValue(JSON.parse(websiteNode.menuHeaderStructure.dom.dom)) : null;
    const domFooter = websiteNode.menuFooterStructure && websiteNode.menuFooterStructure.dom ? extractActiveValue(JSON.parse(websiteNode.menuFooterStructure.dom.dom)) : null;

    const favicon = websiteNode.favicon.file.fileName;

    const seo = JSON.parse(websiteNode.seo.seoConfiguration.value);
    const seoPage = getSeoPage(seo.pages, pageContext.slug);

    return (
        <Structure>
            <HelmetHeader seo={seoPage} seoGlobal={seo.global} locale={pageContext.locale}
                assetsPath={getAssetsPath(location.pathname)} favicon={favicon}/>
            <Container>
                {
                    domNavigation ? domNavigation.map((section, i) => {
                        const children = section.components.map((component, j) => {
                            const customParams = {
                                menu: website.edges.filter(edge => edge.node.node_locale === pageContext.locale)[0].node.menuHeader,
                                location: location,
                                locales: pageContext.locales,
                                locale: pageContext.locale
                            };
                            return getComponent(component, pageContext, getAssetsPath(location.pathname), j, customParams);
                        });
                        return getSection(section, pageContext, getAssetsPath(location.pathname), i, children);
                    })
                        : <div></div>
                }
                <Generated>
                    {
                        dom ? dom.map((section, i) => {
                            const Section = WebComponents[section.model];

                            if (section.model.includes('CustomContent')) {
                                const children = section.components.map((component, j) => getComponent(component, pageContext, getAssetsPath(location.pathname), j));
                                const contentType = section.fields['CustomContentType'].content.contentType;
                                if (contentType === 'publication') {
                                    return (<StaticProviderPublication
                                        locale={pageContext.locale}
                                        contentIds={section.fields['CustomContentType'].content.data}>
                                        {getSection(section, pageContext, getAssetsPath(location.pathname), i, children)}
                                    </StaticProviderPublication>);
                                } else if (contentType === 'event') {
                                    return (<StaticProviderEvent
                                        locale={pageContext.locale}
                                        contentIds={section.fields['CustomContentType'].content.data}>
                                        {getSection(section, pageContext, getAssetsPath(location.pathname), i, children)}
                                    </StaticProviderEvent>);
                                } else if (contentType === 'customerCase') {
                                    return (<StaticProviderCustomerCase
                                        locale={pageContext.locale}
                                        contentIds={section.fields['CustomContentType'].content.data}>
                                        {getSection(section, pageContext, getAssetsPath(location.pathname), i, children)}
                                    </StaticProviderCustomerCase>);
                                }
                            } else if (section.model === 'ListSpeakersFlipCard') {
                                return React.createElement(Section, {
                                    name: section.name,
                                    fields: section.fields,
                                    language: pageContext.locales.indexOf(pageContext.locale),
                                    key: i,
                                    assetsDirectory: getAssetsPath(location.pathname),
                                    data: section.fields['Speakers'].content.speakers.map(id => {
                                        return pageContext.dataset.speakers.filter(speaker => speaker.identifier === id)[0];
                                    })
                                },

                                section.components.map((component, j) => {
                                    const WebComponent = WebComponents[component.model];
                                    return getComponent(component, WebComponent, pageContext, getAssetsPath(location.pathname), j);
                                })
                                );
                            } else if (section.model.includes('Formations')) {
                                return React.createElement(Section, {
                                    name: section.name,
                                    fields: section.fields,
                                    language: pageContext.locales.indexOf(pageContext.locale),
                                    key: i,
                                    assetsDirectory: getAssetsPath(location.pathname),
                                    data: section.fields['Formations'].content.data.map(id => {
                                        return pageContext.dataset.formations.filter(formation => formation.contentful_id === id)[0];
                                    })
                                },
                                section.components.map((component, j) => {
                                    const WebComponent = WebComponents[component.model];
                                    return getComponent(component, WebComponent, pageContext, getAssetsPath(location.pathname), j);
                                })
                                );
                            } else if (section.model.includes('Partners')) {

                                const children = section.components.map((component, j) => getComponent(component, pageContext, getAssetsPath(location.pathname), j));
                                return (<StaticProviderPartner
                                    contentIds={section.fields['Partners'].content.data}>
                                    {getSection(section, pageContext, getAssetsPath(location.pathname), i, children)}
                                </StaticProviderPartner>);
                            } else if (section.model.includes('Categories')) {
                                return React.createElement(Section, {
                                    name: section.name,
                                    fields: section.fields,
                                    language: pageContext.locales.indexOf(pageContext.locale),
                                    key: i,
                                    assetsDirectory: getAssetsPath(location.pathname),
                                    data: section.fields['Category'].content.data.map(id => {
                                        return pageContext.dataset.categories.filter(category => category.contentful_id === id)[0];
                                    })
                                },
                                section.components.map((component, j) => {
                                    const WebComponent = WebComponents[component.model];
                                    return getComponent(component, WebComponent, pageContext, getAssetsPath(location.pathname), j);
                                })
                                );
                            } else if (section.model.includes('Speakers')) {
                                const children = section.components.map((component, j) => getComponent(component, pageContext, getAssetsPath(location.pathname), j));

                                return (<StaticProviderSpeakers
                                    speakersIds={section.fields['Speakers'].content.speakers}
                                    speakersURL={pageContext.dataset.speakers}>
                                    {getSection(section, pageContext, getAssetsPath(location.pathname), i, children)}
                                </StaticProviderSpeakers>);
                            } else if (section.model.includes('Schedule')) {
                                const children = section.components.map((component, j) => getComponent(component, pageContext, getAssetsPath(location.pathname), j));

                                return (<StaticProviderSchedule scheduleURL={pageContext.dataset.schedule}
                                    speakersURL={pageContext.dataset.speakers}>
                                    {getSection(section, pageContext, getAssetsPath(location.pathname), i, children)}
                                </StaticProviderSchedule>);
                            } else {
                                const children = section.components.map((component, j) => getComponent(component, pageContext, getAssetsPath(location.pathname), j));
                                return getSection(section, pageContext, getAssetsPath(location.pathname), i, children);
                            }
                        })
                            : null
                    }
                </Generated>
                {
                    domFooter ? domFooter.map((section, i) => {
                        const children = section.components.map((component, j) => {
                            const customParams = {
                                menu: website.edges.filter(edge => edge.node.node_locale === pageContext.locale)[0].node.menuFooter,
                                location: location,
                                locales: pageContext.locales,
                                locale: pageContext.locale
                            };
                            return getComponent(component, pageContext, getAssetsPath(location.pathname), j, customParams);
                        });
                        return getSection(section, pageContext, getAssetsPath(location.pathname), i, children);
                    })
                        : null
                }
            </Container>
        </Structure>
    );
};

export const query = graphql`
    query($slug: String!, $project: String!) {
        page : contentfulPage(slug: { eq: $slug }, domain: {elemMatch: {slug: {eq: $project}}}) {
            name,
            slug,
            structure {
                ... on ContentfulDom {
                    sectionName
                    dom {
                        dom
                    }
                }
            }
        }
        website : allContentfulWebsite(filter: {slug: {eq: $project}}) {
            edges {
                node {
                    id
                    node_locale
                    favicon {
                        file {
                            fileName
                        }
                    }
                    menuHeader {
                        name
                        slug
                        type
                        urlLink
                        childrens {
                            name
                            slug
                            type
                            urlLink
                            childrens {
                                name
                                slug
                                type
                                urlLink
                            }
                        }
                    }
                    menuHeaderStructure {
                        dom {
                            dom
                        }
                    }
                    menuFooter {
                        name
                        slug
                        type
                        urlLink
                        childrens {
                            name
                            slug
                            type
                            urlLink
                            childrens {
                                name
                                slug
                                type
                                urlLink
                            }
                        }
                    }
                    menuFooterStructure {
                        dom {
                            dom
                        }
                    }
                    seo {
                        seoConfiguration {
                            value
                        }
                    }
                }
            }
        }
    }
`;

export default connect()(PageTemplate);

const getAssetsPath = pathname => pathname === '/' ? './assets/' : `${ pathname.split('/').filter(Boolean).map(i => '../').join('') }assets/`;

const getSeoPage = (pages, slug) => {
    let page = {};
    Object.keys(pages).map(key => {
        if (pages[key].slug[Object.keys(pages[key].slug)[0]] === slug) {
            page = pages[key];
            return page;
        }
    });
    return page;
};


