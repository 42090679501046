import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export const useEvent = () => {
    const { allContentfulCustomerCase } = useStaticQuery(
        graphql`
            query AllCustomerCases {
                allContentfulCustomerCase {
                    nodes {
                        name
                        contentful_id
                        node_locale
                        link
                        image {
                            file {
                                url
                            }
                        }
                        smallImage {
                            file {
                                url
                            }
                        }
                        title
                        subTitle
                        shortPresentation {
                            childMarkdownRemark {
                                html
                            }
                        }
                    }
                }
            }
        `
    );
    return allContentfulCustomerCase;
};

const StaticProviderCustomerCase = ({ contentIds,locale, children }) => {
    const { nodes } = useEvent();
    const data = contentIds.map(id => nodes.find(node => node.contentful_id === id && node.node_locale === locale));
    const component = React.cloneElement(children, { data: data });
    return component;
};

export default StaticProviderCustomerCase;
