const Script = s => {
    const script = document.createElement('script');
    script.innerHTML = s;
    script.setAttribute('type', 'application/ld+json');
    return script;
};

const extractScriptContent = s => {
    let start = '<script type="application/ld+json">';
    let end = '</script>';
    let res = s.substring(s.indexOf(start) + start.length, s.indexOf(end));
    return res;
};

export const initialize = (toHead) => {
    const headScript = extractScriptContent(toHead);

    if (typeof document !== 'undefined') {
        document.head.insertBefore(Script(headScript), document.head.childNodes[0]);
    }
};
