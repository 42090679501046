import React from 'react';

class StaticProviderSpeakers extends React.Component {
    constructor () {
        super();
        this.state = {
            speakers: null,
            component: null
        };
    }

    componentDidMount () {
        fetch(this.props.speakersURL)
            .then(results => {
                return results.json();
            })
            .then(data => {
                const s = this.props.speakersIds.map(id => {
                    return data.filter(speaker => {
                        return speaker.id === id;
                    })[0];
                });

                this.setState({ speakers: s });

                this.setState({
                    component: React.cloneElement(this.props.children, { data: this.state.speakers })
                });
            });
    }

    render () {
        return this.state.component;
    }
}

export default StaticProviderSpeakers;
