//import React, { useEffect, useState } from 'react';
import React from 'react';


/*
const StaticProviderSchedule = ({ scheduleURL, speakersURL, children }) => {
    const [schedule, setSchedule] = useState([]);
    const [speakers, setSpeakers] = useState([]);
    const [component, setComponent] = useState(null);


    useEffect(() => {
        fetch(scheduleURL)
            .then(results => {
                return results.json();
            })
            .then(data => {
                setSchedule(data);
                console.log('data schedule : ', data);

                fetch(speakersURL)
                    .then(results => {
                        return results.json();
                    })
                    .then(data => {
                        console.log('data speakers : ', data);

                        setSpeakers(data);
                        setComponent(React.cloneElement(children, { data: { schedule: schedule, speakers: data } }));
                    });
            });
    });
    //return (schedule && component);
    return  React.cloneElement(children, { data: { schedule: schedule, speakers: speakers } })
};*/

class StaticProviderSchedule extends React.Component {
    constructor () {
        super();
        this.state = {
            schedule: null,
            speakers: null,
            component: null
        };
    }

    componentDidMount () {
        fetch(this.props.scheduleURL)
            .then(results => {
                return results.json();
            })
            .then(data => {
                this.setState({ schedule: data });

                fetch(this.props.speakersURL)
                    .then(results => {
                        return results.json();
                    })
                    .then(data => {
                        this.setState({ speakers: data });

                        this.setState({
                            component: React.cloneElement(this.props.children, { data: { schedule: this.state.schedule, speakers: this.state.speakers } })
                        });
                    });
            });
    }

    render () {
        return this.state.component;
    }
}

export default StaticProviderSchedule;
