import React from 'react';
import ThemeProvider from '../styles/ThemeProvider';

const Structure = ({ children }) => {
    return (
        <div>
            <main>
                {children}
            </main>
            <ThemeProvider/>
        </div>
    );
};

export default Structure;
