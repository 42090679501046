import React from 'react';
import * as WebComponents from 'web-components-common';
import StaticProviderPartner from '../staticProviders/StaticProviderPartner';

export const getComponent = (component, pageContext, assetsDirectory, key, customParams = {}) => {
    const WebComponent = WebComponents[component.model];
    const param = {
        key: key,
        order: component.order || null,
        fields: component.fields,
        language: pageContext.locales.indexOf(pageContext.locale),
        assetsDirectory: assetsDirectory
    };

    if (component.model.includes('Category')) {
        const categoryId = component.fields['Category'].content.data;
        return React.createElement(WebComponent, Object.assign({ ...param, ...customParams }, {
            data: pageContext.dataset.categories.filter(category => category.contentful_id === categoryId)[0]
        }));
    } else if (component.model.includes('TemplatePageFormation')) {
        return React.createElement(WebComponent, Object.assign({ ...param, ...customParams }, {
            data: pageContext.queryResult
        }));
    } else if (component.model.includes('Partners')) {
        const element = React.createElement(WebComponent, Object.assign({ ...param, ...customParams }))
        return (<StaticProviderPartner
            contentIds={component.fields['Partners'].content.data}>
            { element }
        </StaticProviderPartner>);
    } else {
        return React.createElement(WebComponent, { ...param, ...customParams });
    };
};

export const getSection = (section, pageContext, assetsDirectory, key, children) => {
    const WebComponent = WebComponents[section.model];

    const param = {
        key: key,
        name: section.name,
        fields: section.fields,
        language: pageContext.locales.indexOf(pageContext.locale),
        locale: pageContext.locale,
        assetsDirectory: assetsDirectory
    };

    return React.createElement(WebComponent, param, children);
};
