const noScript = iframe => {
    const noscript = document.createElement('noscript');
    noscript.innerHTML = iframe;
    return noscript;
};

const Script = s => {
    const script = document.createElement('script');
    script.innerHTML = s;
    return script;
};

const extractScriptContent = s => {
    let start = '<script>';
    let end = '</script>';
    let res = s.substring(s.indexOf(start) + start.length, s.indexOf(end));
    return res;
};

const extractNoScriptContent = s => {
    let start = '<noscript>';
    let end = '</noscript>';
    let res = s.substring(s.indexOf(start) + start.length, s.indexOf(end));
    return res;
};

export const initialize = (gtmHead, gtmBody) => {
    const headScript = extractScriptContent(gtmHead);
    const bodyScript = extractNoScriptContent(gtmBody);

    if (typeof document !== 'undefined') {
        document.head.insertBefore(Script(headScript), document.head.childNodes[0]);
        document.body.insertBefore(noScript(bodyScript), document.body.childNodes[0]);
    }
};
