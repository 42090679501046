import _ from 'lodash';

const filterActiveSections = dom => dom.filter(section => section.active);

const filterActiveComponents = dom => {
    return dom.map(section => {
        section.components = section.components.filter(component => component.active);
        return section;
    });
};

const filterActiveFields = dom => {
    return dom.map(section => {
        section.components.map(component => {
            _.mapKeys(component.fields, (value, key) => {
                if (!value.active) {
                    _.unset(component.fields, key);
                }
            });
            return component;
        });
        _.mapKeys(section.fields, (value, key) => {
            if (!value.active) {
                _.unset(section.fields, key);
            }
        });
        return section;
    });
};

const extractActiveValue = dom => {
    return filterActiveFields(filterActiveComponents(filterActiveSections(_.cloneDeep(dom))));
};

export {
    extractActiveValue
};
